import logo from './logo.svg';
import './App.scss';
import  DataLoading from './DataLoading/DataLoading';


function App() {
  return (
    <div className="App">
      <header className="App-header">
      
        <DataLoading />

        <p>
        “We’re working hard to make this website available again. <br/>
        Until then we look forward to seeing you on <a href="https://www.facebook.com/Special-effects-Thailandcoltd-270935369592459">our facebook page</a>”
        </p>
      
      </header>
    </div>
  );
}

export default App;
